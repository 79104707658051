@use '../core/mixins/colored-shadows';
@use '../core/mixins/utilities';
@use '../core/mixins/vendor-prefixes';
@use '../core/variables/bootstrap-material-design';
@use '../core/variables/brand';
@use '../core/variables/colors';
@use '../core/variables/modals';
@use '../core/misc';
@use 'bootstrap/scss/bootstrap';

.wrapper {
    &:after {
        display: table;
        clear: both;
        content: ' ';
    }

    &.wrapper-full-page {
        min-height: 100vh;
    }
}

.full-page {
    &:after,
    &:before {
        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
    }

    &:before {
        background-color: rgba(0, 0, 0, 0.5);
    }

    &[filter-color='purple'],
    &[filter-color='primary'] {
        &:after {
            @include utilities.linear-gradient(rgba(colors.$purple-100, 0.56), rgba(colors.$purple-300, 0.95));
        }
        @include vendor-prefixes.lock-page-input-color(brand.$brand-primary);
    }
    &[filter-color='blue'],
    &[filter-color='info'] {
        &:after {
            @include utilities.linear-gradient(rgba(colors.$cyan-100, 0.56), rgba(colors.$cyan-300, 0.95));
        }
        @include vendor-prefixes.lock-page-input-color(brand.$brand-info);
    }
    &[filter-color='green'],
    &[filter-color='success'] {
        &:after {
            @include utilities.linear-gradient(rgba(colors.$green-200, 0.56), rgba(colors.$green-400, 0.95));
        }
        @include vendor-prefixes.lock-page-input-color(brand.$brand-success);
    }
    &[filter-color='orange'],
    &[filter-color='warning'] {
        &:after {
            @include utilities.linear-gradient(rgba(colors.$orange-100, 0.56), rgba(colors.$orange-300, 0.95));
        }
        @include vendor-prefixes.lock-page-input-color(brand.$brand-warning);
    }

    &[filter-color='red'],
    &[filter-color='danger'] {
        &:after {
            @include utilities.linear-gradient(rgba(colors.$red-200, 0.56), rgba(colors.$red-400, 0.95));
        }
        @include vendor-prefixes.lock-page-input-color(brand.$brand-danger);
    }
    &[filter-color='rose'] {
        &:after {
            @include utilities.linear-gradient(rgba(colors.$pink-100, 0.56), rgba(colors.$pink-300, 0.95));
        }
        @include vendor-prefixes.lock-page-input-color(brand.$brand-rose);
    }

    &[data-image]:after {
        opacity: 0.8;
    }

    > .content,
    > .footer {
        position: relative;
        z-index: 4;
    }

    > .content {
        min-height: calc(100vh - 80px);
    }

    .full-page-background {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;
    }

    .footer nav > ul a:not(.btn),
    .footer,
    .footer .copyright a {
        color: bootstrap-material-design.$white-color;
    }
}

.clear-filter {
    &:before {
        display: none;
    }
}

.login-page,
.lock-page {
    > .content {
        padding-top: 18vh;
    }
    .page-header {
        height: 100vh;
    }
}

.login-page {
    .card-login {
        @include vendor-prefixes.transform-translate-y(0);
        @extend .animation-transition-general;

        &.card-hidden {
            opacity: 0;
            @include vendor-prefixes.transform-translate-y(-60px);
        }

        .card-header {
            margin-top: -40px;
            margin-bottom: 20px;
        }

        .card-body {
            padding: 0px 30px 0px 30px;

            .input-group {
                .input-group-text {
                    padding: 15px 15px 0 15px;
                }
                .form-control {
                    padding-bottom: 10px;
                    margin: 17px 0 0 0;
                }
            }
        }

        .social-line .btn {
            margin-left: 5px;
            margin-right: 5px;
        }

        &.card-hidden {
            opacity: 0;
            @include vendor-prefixes.transform-translate-y(-60px);
        }
    }

    .footer {
        .container {
            padding: 0;
        }
        .copyright,
        a {
            color: #ffffff;
        }
    }
}

.lock-page {
    .card-profile {
        width: 240px;
        margin: 60px auto 0;
        color: #ffffff;
        left: 0;
        right: 0;
        display: block;

        @include vendor-prefixes.transform-translate-y(0);
        @extend .animation-transition-general;

        &.card-hidden {
            opacity: 0;
            @include vendor-prefixes.transform-translate-y(-60px);
        }

        .card-avatar {
            max-width: 90px;
            max-height: 90px;
            margin-top: -45px;
        }

        .card-footer {
            border: none;
            padding-top: 0;
        }

        .form-group {
            text-align: left;
            margin-top: 15px;
        }

        &.with-animation {
            @include bootstrap.transition(300ms, modals.$transition-ease-in);
        }

        .card-body + .card-footer {
            padding: 0.9375rem 1.875rem;
            margin: 0;
        }
    }

    .footer {
        z-index: 1;
        color: #fff;

        .copyright {
            a {
                color: colors.$white;

                &:hover {
                    color: brand.$brand-primary;
                }
            }
        }
    }
}

.register-page {
    .card-signup {
        border-radius: bootstrap-material-design.$border-radius-base * 2;
        @include colored-shadows.shadow-16dp();
        margin-bottom: 100px;

        .info {
            max-width: 360px;
            margin: 0 auto;
            padding: 0px;

            .info-title {
                color: #3c4858;
                margin: 30px 0 15px;
            }
        }

        .form-group {
            margin-top: 27px;
            padding-bottom: 0;

            .input-group-text {
                padding-left: 0;
            }
        }
    }

    .container {
        position: relative;
        z-index: 3;
    }

    .footer {
        color: bootstrap-material-design.$white-color;

        .container {
            padding: 0;
        }
        .copyright {
            a {
                color: colors.$white;

                &:hover {
                    color: brand.$brand-primary;
                }
            }
        }
    }

    .form-check {
        label {
            margin-left: 27px;
        }
        .form-check-label {
            padding-left: 34px;
        }
    }
}

.pricing-page {
    padding: 0 !important;

    .title {
        color: bootstrap-material-design.$white-color;
        margin-top: 5vh;
    }

    .section-space {
        display: block;
        height: 70px;
    }

    .card-plain {
        .icon i,
        .card-title {
            color: bootstrap-material-design.$white-color;
        }
    }

    .description {
        color: bootstrap-material-design.$white-color;
    }
    &.full-page:before {
        background-color: rgba(0, 0, 0, 0.65);
    }

    .footer {
        z-index: 2;
        color: bootstrap-material-design.$white-color;

        .container {
            padding: 0;
        }
        .copyright {
            a {
                color: colors.$white;

                &:hover {
                    color: brand.$brand-primary;
                }
            }
        }
    }
}

.off-canvas-sidebar {
    .navbar-toggler .navbar-toggler-icon {
        background-color: bootstrap-material-design.$white-color !important;
    }

    .navbar-collapse {
        .nav {
            > li > a,
            > li > a:hover {
                color: bootstrap-material-design.$white-color;
                margin: 0 15px;
            }

            > li > a:focus,
            > li > a:hover {
                background: rgba(200, 200, 200, 0.2);
            }
        }
        &:after {
            background-color: #282828;
        }
    }
}

.offline-doc {
    .navbar .navbar-nav {
        .nav-item .nav-link {
            color: bootstrap-material-design.$white-color;
        }
    }
}

@media screen and (max-width: 991px) {
    .off-canvas-sidebar {
        .page-header {
            > .container {
                padding-top: 70px;
                padding-bottom: 200px;
            }
        }
    }
}
