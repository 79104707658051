@use 'hover';

@mixin bmd-tabs-color($color, $active-color, $active-border, $disabled-link-color, $disabled-link-hover-color) {
    .nav-link {
        color: $color;

        &.active {
            color: $active-color;
            border-color: $active-border;
            @include hover.hover-focus {
                border-color: $active-border;
            }
        }

        // Disabled state lightens text and removes hover/tab effects
        &.disabled {
            color: $disabled-link-color;

            @include hover.plain-hover-focus {
                color: $disabled-link-hover-color;
            }
        }
    }
}
