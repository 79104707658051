full-calendar {
    .fc-button {
        background-color: #0d47a1;
        border-color: #0d47a1;
        text-transform: uppercase;
        box-shadow:
            0 2px 2px 0 rgba(13, 71, 161, 0.14),
            0 3px 1px -2px rgba(13, 71, 161, 0.2),
            0 1px 5px 0 rgba(13, 71, 161, 0.12);
        font-size: 0.75rem;
        padding: 10px 24px;
        font-weight: 400;
    }

    .fc-button:hover,
    .fc-button:active {
        background-color: #0d47a1 !important;
        border-color: #0d47a1 !important;
    }

    .fc-button:disabled {
        background-color: gray;
        border-color: gray;
        cursor: not-allowed;
    }

    .fc-day-today {
        background-color: #e7edf6;
    }
}
