@use '../core/navbar';
@use '../core/variables/bootstrap-material-design';

.navbar {
    &.navbar-transparent {
        padding-top: 10px;
    }
}

.off-canvas-sidebar {
    .navbar {
        .navbar-collapse {
            .navbar-nav .nav-item {
                .nav-link {
                    padding-top: 15px;
                    padding-bottom: 15px;
                    font-weight: 500;
                    font-size: 12px;
                    text-transform: uppercase;
                    border-radius: 3px;
                    color: bootstrap-material-design.$white-color;
                    margin: 0 15px;

                    &:hover {
                        background: rgba(200, 200, 200, 0.2);
                    }
                }
            }
        }
    }
}
