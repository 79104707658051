@use '../variables/bootstrap-material-design';
@use '../variables/colors';

.card-pricing {
    text-align: center;

    &:after {
        background-color: rgba(0, 0, 0, 0.7) !important;
    }

    .card-title {
        margin-top: 30px;
    }
    .card-body {
        padding: 15px !important;
        margin: 0 !important;
    }

    .card-icon {
        padding: 10px 0 0px;

        i {
            font-size: 55px;
            border: 1px solid #e5e5e5;
            border-radius: 50%;
            width: 130px;
            line-height: 130px;
            height: 130px;
        }
    }

    h1 {
        small {
            font-size: 18px;
            display: inline-flex;
            height: 0;

            &:first-child {
                position: relative;
                top: -17px;
                font-size: 26px;
            }
        }
    }

    ul {
        list-style: none;
        padding: 0;
        max-width: 240px;
        margin: 10px auto;

        li {
            color: colors.$gray-color;
            text-align: center;
            padding: 12px 0;
            border-bottom: 1px solid rgba(colors.$gray-color, 0.3);

            &:last-child {
                border: 0;
            }
            b {
                color: bootstrap-material-design.$black-color;
            }
            i {
                top: 6px;
                position: relative;
            }
        }
    }

    &.card-background,
    &[class*='bg-'] {
        ul {
            li {
                color: bootstrap-material-design.$white-color;
                border-color: rgba(bootstrap-material-design.$white-color, 0.3);

                b {
                    color: bootstrap-material-design.$white-color;
                    font-weight: 700;
                }
            }
        }
        [class*='text-'],
        .card-category {
            color: bootstrap-material-design.$white-color !important;
        }
    }

    .card-footer {
        z-index: 2;
    }
}
