@use '../variables/bootstrap-material-design';

.card-signup {
    padding-bottom: 10px;

    .card-header {
        // @include shadow-big();
        margin-left: 20px;
        margin-right: 20px;
        margin-top: -40px;
        padding: 20px 0;
        margin-bottom: 15px;
    }

    .text-divider {
        margin-top: 30px;
        margin-bottom: 0px;
        text-align: center;
    }

    .card-body {
        margin-top: 30px;
        padding: 0px 30px 0px 10px;
    }

    .form-check {
        label {
            margin-left: 18px;
        }
        .form-check-sign {
            padding-right: 27px;
        }
    }

    .social-line {
        margin-top: bootstrap-material-design.$bmd-label-top-margin-base;
        text-align: center;
        padding: 0;

        .btn {
            color: bootstrap-material-design.$white-color;
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}
