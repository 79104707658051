@use './card-testimonials';

.card-profile {
    @extend %common-card-testimonial;
}

.card-profile {
    .card-header:not([class*='card-header-']) {
        background: transparent;
        box-shadow: none;
    }
    .card-avatar {
        position: relative;
        max-width: 130px;
        max-height: 130px;
    }
}
