@use 'variables/bootstrap-material-design';

.page-header {
    height: 100%;
    min-height: stretch;
    background-position: center center;
    background-size: cover;
    margin: 0;
    padding: 0;
    border: 0;
    display: flex;
    align-items: center;

    .carousel .carousel-indicators {
        bottom: 60px;
    }

    > .container {
        color: bootstrap-material-design.$white-color;
    }

    .title {
        color: bootstrap-material-design.$white-color;
    }

    &.header-small {
        height: 65vh;
        min-height: 65vh;
    }

    .iframe-container {
        iframe {
            width: 100%;
            box-shadow:
                0 16px 38px -12px rgba(0, 0, 0, 0.56),
                0 4px 25px 0px rgba(0, 0, 0, 0.12),
                0 8px 10px -5px rgba(0, 0, 0, 0.2);
        }
    }
}

.header-filter {
    position: relative;

    &:before,
    &:after {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: '';
    }

    &::before {
        background: rgba(0, 0, 0, 0.5);
    }

    .container {
        z-index: 2;
        position: relative;
    }
}
.clear-filter::before {
    background: none;
}

.purple-filter:after {
    background: rgba(101, 47, 142, 0.64);
    background: linear-gradient(45deg, rgba(101, 47, 142, 0.88) 0%, rgba(125, 46, 185, 0.45) 100%);
    background: -moz-linear-gradient(135deg, rgba(101, 47, 142, 0.88) 0%, rgba(125, 46, 185, 0.45) 100%);
    background: -webkit-linear-gradient(135deg, rgba(101, 47, 142, 0.88) 0%, rgba(125, 46, 185, 0.45) 100%);
}
