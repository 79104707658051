@use '../core/cards';

// cards

@use '../core/cards/card-testimonials';
@use '../core/cards/card-profile';
@use '../core/cards/card-blog';
@use '../core/cards/card-product';
@use '../core/cards/card-pricing';
@use '../core/cards/card-collapse';
@use '../core/cards/card-signup';
@use '../core/cards/card-form-horizontal';
@use '../core/cards/card-background';
@use '../core/mixins/colored-shadows';
@use '../core/mixins/vendor-prefixes';
@use '../core/variables/bootstrap-material-design';
@use '../core/variables/colors';
@use '../core/variables/type';

// dashboard Cards

.card {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);

    .table tr:first-child td {
        border-top: none;
    }

    .card-title {
        margin-top: 0;
        margin-bottom: 3px;
    }

    .card-body {
        padding: bootstrap-material-design.$padding-card-body-y 30px;
        position: relative;
    }

    .card-header {
        z-index: 3 !important;

        .card-category {
            margin: 0;
        }

        &.card-header-text {
            display: inline-block;

            &:after {
                content: '';
                display: table;
            }
        }

        &.card-header-icon,
        &.card-header-text {
            i {
                width: 33px;
                height: 33px;
                text-align: center;
                line-height: 33px;
            }
            .card-title {
                margin-top: 15px;
                color: bootstrap-material-design.$black-color;
            }
            h4 {
                font-weight: 300;
            }
        }

        &.card-header-tabs {
            .nav-tabs {
                background: transparent;
                padding: 0;
            }
            .nav-tabs-title {
                float: left;
                padding: 10px 10px 10px 0;
                line-height: 24px;
            }
        }
    }

    &.card-plain {
        .card-header {
            &.card-header-icon + .card-body .card-title,
            &.card-header-icon + .card-body .card-category {
                margin-top: -20px;
            }
        }
    }

    .card-actions {
        position: absolute;
        z-index: 1;
        top: -50px;
        width: calc(100% - 30px);
        left: 17px;
        right: 17px;
        text-align: center;

        .card-header {
            padding: 0;
            min-height: 160px;
        }

        .btn {
            padding-left: 12px;
            padding-right: 12px;
        }
        .fix-broken-card {
            position: absolute;
            top: -65px;
        }
    }

    &.card-chart {
        .card-footer i:nth-child(1n + 2) {
            width: 18px;
            text-align: center;
        }

        .card-category {
            margin: 0;
        }
    }

    .card-body + .card-footer,
    .card-footer {
        padding: 0;
        padding-top: 10px;
        margin: 0 15px 10px;
        border-radius: 0;
        justify-content: space-between;
        align-items: center;

        h6 {
            width: 100%;
        }

        .stats {
            color: #999999;
            font-size: 12px;
            line-height: 22px;

            .card-category {
                padding-top: 7px;
                padding-bottom: 7px;
                margin: 0;
            }

            .material-icons {
                position: relative;
                top: 4px;
                font-size: 16px;
            }
        }
    }
    [class*='card-header-'] {
        margin: 0px 15px 0;
        padding: 0;
        position: relative;

        &:not(.card-header-icon):not(.card-header-text):not(.card-header-image) {
            border-radius: bootstrap-material-design.$border-radius-base;
            margin-top: -20px;
            padding: 15px;
        }

        .card-icon,
        .card-text {
            border-radius: bootstrap-material-design.$border-radius-base;
            background-color: colors.$gray-color;
            padding: 15px;
            margin-top: -20px;
            margin-right: 15px;
            float: left;
        }

        .card-text {
            float: none;
            display: inline-block;
            margin-right: 0;

            .card-title {
                color: bootstrap-material-design.$white-color;
                margin-top: 0;
            }
        }

        .ct-chart {
            .card-title {
                color: bootstrap-material-design.$white-color;
            }
            .card-category {
                margin-bottom: 0;
                color: rgba(bootstrap-material-design.$white-color, 0.62);
            }

            .ct-label {
                color: rgba(bootstrap-material-design.$white-color, 0.7);
            }
            .ct-grid {
                stroke: rgba(255, 255, 255, 0.2);
            }
            .ct-series-a .ct-point,
            .ct-series-a .ct-line,
            .ct-series-a .ct-bar,
            .ct-series-a .ct-slice-donut {
                stroke: rgba(255, 255, 255, 0.8);
            }
            .ct-series-a .ct-slice-pie,
            .ct-series-a .ct-area {
                fill: rgba(255, 255, 255, 0.4);
            }
            .ct-series-a .ct-bar {
                stroke-width: 10px;
            }
            .ct-point {
                stroke-width: 10px;
                stroke-linecap: round;
            }
            .ct-line {
                fill: none;
                stroke-width: 4px;
            }
        }
    }

    [data-header-animation='true'] {
        @include vendor-prefixes.transform-translate-y(0);
        -webkit-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
        -moz-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
        -o-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
        -ms-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
        transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    }

    &:hover {
        [data-header-animation='true'] {
            @include vendor-prefixes.transform-translate-y(-50px);
        }
    }

    &.card-stats {
        .card-header {
            &.card-header-icon,
            &.card-header-text {
                text-align: right;
            }

            .card-icon + .card-title,
            .card-icon + .card-category {
                padding-top: 10px;
            }

            .card-title,
            .card-category {
                margin: 0;
            }
            .card-category {
                margin-bottom: 0;
                margin-top: 0;

                &:not([class*='text-']) {
                    color: colors.$gray-color;
                    font-size: type.$font-paragraph;
                }
            }

            & + .card-footer {
                border-top: 1px solid #eee;
                margin-top: 20px;
            }

            i {
                font-size: 36px;
                line-height: 56px;
                width: 56px;
                height: 56px;
                text-align: center;
            }
        }

        .card-body {
            text-align: right;
        }
    }

    .map {
        height: 280px;
        border-radius: bootstrap-material-design.$border-radius-large;
        margin-top: 15px;

        &.map-big {
            height: 420px;
        }
    }

    .card-body.table-full-width {
        padding: 0;
    }

    .card-plain .card-header-icon {
        margin-right: 15px !important;
    }
}

.table-sales {
    margin-top: 40px;
}

.iframe-container {
    margin: 0 -20px 0;

    iframe {
        width: 100%;
        height: 500px;
        border: 0;
        @include colored-shadows.shadow-big();
    }
}

.card-chart,
.card-product {
    .card-body + .card-footer {
        border-top: 1px solid #eee;
    }
}

.card-product {
    .price {
        color: inherit;
    }
}

.card-collapse {
    margin-bottom: 15px;

    .card .card-header a[aria-expanded='true'] {
        color: #e91e63;
    }
}
