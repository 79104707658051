@use 'sass:map';
@use '@angular/material' as mat;

@mixin date-range-picker-theme($theme) {
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');

    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .md-drppicker {
        background-color: mat.m2-get-color-from-palette($background, raised-button);
        color: mat.m2-get-color-from-palette($foreground, text);

        td,
        th {
            &.available {
                &:hover {
                    background-color: mat.m2-get-color-from-palette($background, hover);
                    color: mat.m2-get-color-from-palette($foreground, text);
                }
            }
        }

        td {
            &.off {
                &,
                &.in-range,
                &.start-date,
                &.end-date {
                    background-color: mat.m2-get-color-from-palette($background, dialog);
                    border-color: mat.m2-get-color-from-palette($background, dialog);
                    color: mat.m2-get-color-from-palette($foreground, disabled);
                }
            }

            &.in-range {
                background-color: mat.m2-get-color-from-palette($background, disabled-list-option);
                border-color: mat.m2-get-color-from-palette($background, disabled-list-option);
                color: mat.m2-get-color-from-palette($foreground, text);
            }

            &.active {
                transition: background 300ms ease-out, border 300ms ease-out;
                background: rgba(0, 0, 0, 0.1);
                &,
                &:hover {
                    background-color: mat.m2-get-color-from-palette($primary);
                    color: mat.m2-get-color-from-palette($primary, default-contrast);
                }
            }
        }

        .ranges {
            ul {
                li {
                    button {
                        border-radius: 4px;
                        color: mat.m2-get-color-from-palette($foreground, text);

                        &.active {
                            background-color: mat.m2-get-color-from-palette($primary);
                            color: mat.m2-get-color-from-palette($primary, default-contrast);
                        }
                    }

                    &:hover {
                        border-radius: 4px;
                        background-color: mat.m2-get-color-from-palette($background, hover);
                        color: mat.m2-get-color-from-palette($primary, default-contrast);
                    }
                }
            }
        }
    }
}
