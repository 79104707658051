@use '../variables/bootstrap-material-design';
@use '../variables/bootstrap-material-design-base';
@use '../variables/brand';
@use '../variables/forms' as variables-forms;
@use 'bootstrap/scss/bootstrap';

@mixin bmd-disabled() {
    fieldset[disabled][disabled] &,
    &.disabled,
    &:disabled,
    &[disabled] {
        @content;
    }
}

// Placeholder text
@mixin material-placeholder() {
    &::-moz-placeholder {
        @content;
    } // Firefox
    &:-ms-input-placeholder {
        @content;
    } // Internet Explorer 10+
    &::-webkit-input-placeholder {
        @content;
    } // Safari and Chrome
}

@mixin bmd-selection-color() {
    .radio label,
    .radio-inline,
    .checkbox label,
    .checkbox-inline,
    .switch label {
        // override bootstrap focus and keep all the standard color (could be multiple radios in the form group)
        //color: $bmd-label-color;

        &,
        .is-focused & {
            // form-group focus could change multiple checkboxes/radios, disable that change by using the same color as non-form-group is-focused
            color: bootstrap-material-design-base.$bmd-label-color;

            // correct the above focus color for disabled items
            label:has(input[type=radio][disabled]),
      /* css 4 which is unlikely to work for a while, but no other pure css way.*/
      label:has(input[type=checkbox][disabled]),
      /* css 4*/
      fieldset[disabled] & {
                &,
                &:hover,
                &:focus {
                    color: bootstrap-material-design-base.$bmd-label-color;
                }
            }
        }
    }

    // Style for disabled inputs  OLD, use color approach with opacity built in, see radios
    //fieldset[disabled] &,
    //fieldset[disabled] & input[type=checkbox],
    //input[type=checkbox][disabled]:not(:checked) ~ .checkbox-decorator .check::before,
    //input[type=checkbox][disabled]:not(:checked) ~ .checkbox-decorator .check,
    //input[type=checkbox][disabled] + .bmd-radio-outer-circle {
    //  opacity: 0.5;
    //}
}

@mixin bmd-radio-color($color) {
    &::after {
        border-color: $color;
    }
    &::before {
        background-color: $color;
    }
}

@mixin bmd-form-color($label-color, $label-color-focus, $border-color, $line-color) {
    [class^='bmd-label'],
    [class*=' bmd-label'] {
        color: $label-color;
    }

    // may or may not be a form-group or bmd-form-group
    .is-focused {
        // on focus set borders and labels to the validation color

        // Use the BS provided mixin for the bulk of the color
        [class^='bmd-label'],
        [class*=' bmd-label'] {
            color: $label-color-focus;
        }

        .bmd-label-placeholder {
            color: $label-color; // keep the placeholder color
        }

        // Set the border and box shadow on specific inputs to match
        .form-control {
            border-color: $border-color;
        }

        // Set validation states also for addons
        //.input-group-addon {
        //  border-color: $border-color;
        //}

        .bmd-help {
            color: bootstrap-material-design-base.$bmd-label-color-inner-focus;
        }
    }
}

// must be broken out for reuse - webkit selector breaks firefox
@mixin bmd-label-static($label-top, $static-font-size) {
    top: $label-top;
    left: 0;
    // must repeat because the previous (more generic) selectors
    font-size: $static-font-size;
}

@mixin bmd-form-size-variant($font-size, $label-top-margin, $variant-padding-y, $variant-line-height, $form-group-context: null) {
    $variant-input-height: (($font-size * $variant-line-height) + ($variant-padding-y * 2));
    // $static-font-size: ($bmd-bmd-label-static-size-ratio * $font-size);
    $floating-font-size: 0.6875rem;
    $static-font-size: 0.875rem;
    $help-font-size: (bootstrap-material-design.$bmd-help-size-ratio * $font-size);

    $label-static-top: $label-top-margin;
    $label-placeholder-top: $label-top-margin + $static-font-size + $variant-padding-y;

    //@debug "font-size: #{$font-size} static-font-size: #{$static-font-size} help-font-size: #{$help-font-size} form-group-context: #{$form-group-context} ";

    //Label height: 72dp
    //Padding above label text: 16dp
    //Padding between label and input text: 8dp
    //Padding below input text (including divider): 16dp
    //Padding below text divider: 8dp

    // @if $form-group-context {
    //   // Create a space at the top of the bmd-form-group for the label.
    //   //  The label is absolutely positioned, so we use top padding to make space.  This padding extends over the label down to the top of the input (padding).
    //   padding-top: ($label-top-margin + $static-font-size);
    //   // note: bottom-margin of this is determined by $spacer.  @see _spacer.scss
    //   //margin-bottom: (1.5 * $help-font-size);
    // }

    // TODO: remove this when known stable.  https://github.com/FezVrasta/bootstrap-material-design/issues/849
    //@else {
    //
    //  // for radios and checkboxes without a form-group, add some extra vertical spacing to pad down so that
    //  //  any help text above is not encroached upon, or so that it appears more evenly spaced vs form-groups
    //  .radio,
    //  label.radio-inline,
    //  .checkbox,
    //  label.checkbox-inline,
    //  .switch {
    //    padding-top: $spacer-y;
    //  }
    //}

    // Set all line-heights preferably to 1 so that we can space out everything manually without additional added space
    //  from the default line-height of 1.5
    .form-control,
    label,
    input::placeholder {
        line-height: $variant-line-height + 0.1;
    }

    .radio label,
    label.radio-inline,
    .checkbox label,
    label.checkbox-inline,
    .switch label {
        line-height: bootstrap.$line-height-base; // keep the same line height for radios and checkboxes
    }

    // Note: this may be inside or outside a form-group, may be .bmd-form-group.bmd-form-group-sm or .bmd-form-group.bmd-form-group-lg
    // input::placeholder {
    //   font-size: $font-size;
    // }

    // generic labels used anywhere in the form
    .checkbox label,
    .radio label,
    label {
        font-size: bootstrap.$font-size-sm;
    }

    // floating/placeholder default (no focus)
    .bmd-label-floating,
    .bmd-label-placeholder {
        //@debug "top: #{$label-as-placeholder-top}";
        top: $label-placeholder-top - 1.7; // place the floating label to look like a placeholder with input padding
    }

    // floating focused/filled will look like static
    .is-focused,
    .is-filled {
        .bmd-label-floating {
            @include bmd-label-static($label-static-top - 2, $floating-font-size);
        }
    }

    // static
    .bmd-label-static {
        @include bmd-label-static($label-static-top - 0.65, $static-font-size);
    }
    // #559 Fix for webkit/chrome autofill - rule must be separate because it breaks firefox otherwise #731
    //input:-webkit-autofill ~ .bmd-label-floating {  FIXME: confirm that the autofill js generation of change event makes this unnecessary
    //  @include bmd-label-static($label-top, $static-font-size, $static-line-height);
    //}

    .bmd-help {
        margin-top: 0; // allow the input margin to set-off the top of the help-block
        font-size: $help-font-size;
    }

    // validation icon placement
    .form-control {
        &.form-control-success,
        &.form-control-warning,
        &.form-control-danger {
            $icon-bg-size: ($variant-input-height * 0.5) ($variant-input-height * 0.5);
            background-size: bootstrap-material-design.$bmd-form-control-bg-size, $icon-bg-size;

            &,
            &:focus,
            .bmd-form-group.is-focused & {
                padding-right: (variables-forms.$input-padding-x * 3);
                background-repeat: bootstrap-material-design.$bmd-form-control-bg-repeat-y, no-repeat;
                background-position:
                    bootstrap-material-design.$bmd-form-control-bg-position,
                    center right ($variant-input-height * 0.25);
            }

            &:focus,
            .bmd-form-group.is-focused & {
                background-size: bootstrap-material-design.$bmd-form-control-bg-size-active, $icon-bg-size;
            }
        }
    }
}

@mixin mdb-label-color-toggle-focus() {
    // override bootstrap focus and keep all the standard color (could be multiple radios in the form group)
    .form-group.is-focused & {
        color: bootstrap-material-design.$mdb-label-color;

        // on focus just darken the specific labels, do not turn them to the brand-primary
        &:hover,
        &:focus {
            color: bootstrap-material-design.$mdb-label-color-toggle-focus;
        }

        // correct the above focus color for disabled items
        fieldset[disabled] & {
            color: bootstrap-material-design.$mdb-label-color;
        }
    }
}

@mixin animation($value) {
    -webkit-animation: $value;
    -moz-animation: $value;
    -o-animation: $value;
    -ms-animation: $value;
    animation: $value;
}

@mixin transform-scale3d($value) {
    -webkit-transform: scale3d($value);
    -moz-transform: scale3d($value);
    -o-transform: scale3d($value);
    -ms-transform: scale3d($value);
    transform: scale3d($value);
}

@mixin create-colored-tags() {
    &.tag-primary {
        @include tag-color(brand.$brand-primary);
    }
    &.tag-info {
        @include tag-color(brand.$brand-info);
    }
    &.tag-success {
        @include tag-color(brand.$brand-success);
    }
    &.tag-warning {
        @include tag-color(brand.$brand-warning);
    }
    &.tag-danger {
        @include tag-color(brand.$brand-danger);
    }
    &.tag-rose {
        @include tag-color(brand.$brand-rose);
    }
}
@mixin tag-color($color) {
    .tag {
        background-color: $color;
        color: bootstrap-material-design.$white-color;
        .tagsinput-remove-link {
            color: bootstrap-material-design.$white-color;
        }
    }
    .tagsinput-add {
        color: $color;
    }
}

// variations(unquote(""), background-color, #FFF);
@mixin variations($component, $selector-suffix, $mdb-param-1, $color-default) {
    // @include generic-variations($component, $selector-suffix, $color-default, "variations-content", $mdb-param-1);
}
