a {
    &:focus {
        outline: none; // remove the terrible @include tab-focus();
    }
}

button:focus {
    outline: none;
    //outline: 1px dotted;
    //outline: 5px auto -webkit-focus-ring-color;
}
