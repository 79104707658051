@use '../core/variables/bootstrap-material-design';
@use '../core/variables/bootstrap-material-design-base';
@use '../core/variables/card';

.timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
    margin-top: 30px;

    &:before {
        top: 50px;
        bottom: 0;
        position: absolute;
        content: ' ';
        width: 3px;
        background-color: #e5e5e5;
        left: 50%;
        margin-left: -1px;
    }

    h6 {
        color: bootstrap-material-design-base.$gray-dark;
        font-weight: 400;
        margin: 10px 0px 0px;
    }

    &.timeline-simple {
        margin-top: 30px;
        padding: 0 0 20px;

        &:before {
            left: 5%;
            background-color: #e5e5e5;
        }

        > li > .timeline-panel {
            width: 86%;
        }

        > li > .timeline-badge {
            left: 5%;
        }
    }

    > li {
        margin-bottom: 20px;
        position: relative;

        &:before,
        &:after {
            content: ' ';
            display: table;
            clear: both;
        }
    }

    > li > .timeline-panel {
        width: 45%;
        float: left;
        padding: 20px;
        margin-bottom: 20px;
        position: relative;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
        border-radius: bootstrap-material-design.$border-radius-large;
        color: card.$mdb-card-body-text;
        background: card.$mdb-card-body-background;

        &:before {
            position: absolute;
            top: 26px;
            right: -15px;
            display: inline-block;
            border-top: 15px solid transparent;
            border-left: 15px solid #e4e4e4;
            border-right: 0 solid #e4e4e4;
            border-bottom: 15px solid transparent;
            content: ' ';
        }

        &:after {
            position: absolute;
            top: 27px;
            right: -14px;
            display: inline-block;
            border-top: 14px solid transparent;
            border-left: 14px solid bootstrap-material-design.$white-color;
            border-right: 0 solid bootstrap-material-design.$white-color;
            border-bottom: 14px solid transparent;
            content: ' ';
        }
    }

    > li > .timeline-badge {
        color: bootstrap-material-design.$white-color;
        width: 50px;
        height: 50px;
        line-height: 51px;
        font-size: 1.4em;
        text-align: center;
        position: absolute;
        top: 16px;
        left: 50%;
        margin-left: -24px;
        z-index: 2;
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
        background-color: #0c4398;

        [class^='ti-'],
        [class*=' ti-'],
        [class='material-icons'] {
            line-height: inherit;
        }
    }

    > li.timeline-inverted > .timeline-panel {
        float: right;
        background-color: bootstrap-material-design.$white-color;

        &:before {
            border-left-width: 0;
            border-right-width: 15px;
            left: -15px;
            right: auto;
        }

        &:after {
            border-left-width: 0;
            border-right-width: 14px;
            left: -14px;
            right: auto;
        }
    }
}
.timeline-heading {
    margin-bottom: 15px;
}

.timeline-title {
    margin-top: 0;
    color: inherit;
}

.timeline-body {
    hr {
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .btn {
        margin-bottom: 0;
    }
}
.timeline-body > p,
.timeline-body > ul {
    margin-bottom: 0;
}

.timeline-body > p + p {
    margin-top: 5px;
}

[class*='col-'].cards {
    float: left;
}
