@use 'variables/nav';

// https://www.google.com/design/spec/components/tabs.html#tabs-specs

.nav-link {
    //line-height: $bmd-nav-link-line-height; // set baseline line-height and font sizes
    text-transform: uppercase;
    user-select: none;
}

// navbars
.navbar-nav {
    .nav-link {
        padding: nav.$bmd-navbar-link-padding;
        font-size: nav.$bmd-navbar-link-font-size;
        font-weight: nav.$bmd-navbar-link-font-weight;
    }
}

// used to correctly size ripple container
.nav {
    .nav-item {
        position: relative;
    }
}
