@use 'mixins/navbar-colors';
@use 'variables/bootstrap-material-design';
@use 'variables/colors';
@use 'bootstrap/scss/bootstrap';

.navbar {
    border: 0;
    border-radius: bootstrap-material-design.$border-radius-base;
    padding: 0.625rem 0;
    margin-bottom: 20px;
    @include navbar-colors.navbar-colors(bootstrap-material-design.$white-color, bootstrap-material-design.$navbar-color);

    &.fixed-top {
        border-radius: 0;
    }

    .navbar-toggler {
        cursor: pointer;

        .navbar-toggler-icon {
            width: 22px;
            height: 2px;
            vertical-align: middle;
            outline: 0;
            display: block;
            border-radius: 1px;

            & + .navbar-toggler-icon {
                margin-top: 4px;
            }
        }
    }

    .navbar-wrapper {
        display: inline-flex;
        align-items: center;
    }

    // give correct size to ripple container
    .navbar-brand {
        position: relative;
        color: inherit;
        height: 50px;
        font-size: bootstrap.$navbar-brand-font-size - 0.125;
        line-height: 30px;
        padding: bootstrap-material-design.$padding-general-y 0;
        font-weight: 300;
        margin-left: 1rem;
    }

    > .container {
        flex: 1;
    }

    &.bg-primary {
        @include navbar-colors.navbar-colors(bootstrap-material-design.$bg-primary, bootstrap-material-design.$white-color);
    }
    &.bg-info {
        @include navbar-colors.navbar-colors(bootstrap-material-design.$bg-info, bootstrap-material-design.$white-color);
    }

    &.bg-warning {
        @include navbar-colors.navbar-colors(bootstrap-material-design.$bg-warning, bootstrap-material-design.$white-color);
    }

    &.bg-rose {
        @include navbar-colors.navbar-colors(bootstrap-material-design.$bg-rose, bootstrap-material-design.$white-color);
    }

    &.bg-danger {
        @include navbar-colors.navbar-colors(bootstrap-material-design.$bg-danger, bootstrap-material-design.$white-color);
    }

    &.bg-success {
        @include navbar-colors.navbar-colors(bootstrap-material-design.$bg-success, bootstrap-material-design.$white-color);
    }
    &.bg-dark {
        @include navbar-colors.navbar-colors(colors.$grey-900, bootstrap-material-design.$white-color);
    }

    &.navbar-transparent {
        background-color: transparent !important;
        box-shadow: none;
        padding-top: 25px;
    }
}

// for demo
#navbar .navbar {
    border-radius: 0;
}
