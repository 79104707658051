@use 'variables/bootstrap-material-design';
@use 'variables/bootstrap-material-design-base';
@use 'variables/brand';
@use 'variables/colors';
@use 'variables/type';

html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
    font-family: type.$font-family-sans-serif;
    font-weight: 300;
    line-height: 1.5em;
}

h1,
.h1 {
    font-size: type.$font-size-h1;
    line-height: 1.15em;
}
h2,
.h2 {
    font-size: type.$font-size-h2;
}
h3,
.h3 {
    font-size: type.$font-size-h3;
    line-height: 1.4em;
    margin: 20px 0 10px;
}
h4,
.h4 {
    font-size: type.$font-size-h4;
    line-height: 1.4em;
    font-weight: 300;
}
h5,
.h5 {
    font-size: type.$font-size-h5;
    line-height: 1.4em;
    margin-bottom: 15px;
}
h6,
.h6 {
    font-size: type.$font-size-h6;
    text-transform: uppercase;
    font-weight: bootstrap-material-design.$font-weight-bold;
}

.title,
.card-title,
.info-title,
.footer-brand,
.footer-big h5,
.footer-big h4,
.media .media-heading {
    //font-weight: $font-weight-extra-bold;
    // font-family: $font-family-serif;

    &,
    a {
        color: bootstrap-material-design.$black-color;
        text-decoration: none;
    }
}

.card-blog .card-title {
    font-weight: bootstrap-material-design.$font-weight-extra-bold;
}

h2.title {
    margin-bottom: bootstrap-material-design.$margin-base * 2;
}

.description,
.card-description,
.footer-big p {
    color: bootstrap-material-design-base.$gray-light;
}

.text-warning {
    color: brand.$brand-warning !important;
}
.text-primary {
    color: brand.$brand-primary !important;
}
.text-danger {
    color: brand.$brand-danger !important;
}
.text-success {
    color: brand.$brand-success !important;
}
.text-info {
    color: brand.$brand-info !important;
}
.text-rose {
    color: brand.$brand-rose !important;
}
.text-gray {
    color: colors.$gray-color !important;
}
