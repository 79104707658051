// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use '@material/line-ripple/mdc-line-ripple';
@use '@timebutt/ngx-daterangepicker-material/daterangepicker-theme' as carousel;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.all-component-typographies(mat.m2-define-typography-config());
@include mat.elevation-classes();
@include mat.app-background();
@include mat.typography-hierarchy(
    mat.m2-define-typography-config($headline-1: mat.m2-define-typography-level(42px, 42px, 100)),
    '.custom-typography'
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// Theme Config
body {
    --primary-color: #0d47a1;
    --primary-lighter-color: #b6c8e3;
    --primary-darker-color: #072f85;
    --text-primary-color: #{white};
    --text-primary-lighter-color: #{rgba(black, 0.87)};
    --text-primary-darker-color: #{white};
    --mat-select-panel-background-color: #ffffff;
    --warn-color: #ff0000;
    --warn-lighter-color: #ffb3b3;
    --warn-darker-color: #ff0000;
    --text-warn-color: #{white};
    --text-warn-lighter-color: #{rgba(black, 0.87)};
    --text-warn-darker-color: #{white};
}

$mat-primary: (
    main: #0d47a1,
    lighter: #b6c8e3,
    darker: #072f85,
    200: #0d47a1,
    // For slide toggle,
    contrast: (
            main: white,
            lighter: rgba(black, 0.87),
            darker: white,
        ),
);
$theme-primary: mat.m2-define-palette($mat-primary, main, lighter, darker);

$mat-accent: (
    main: #0d47a1,
    lighter: #b6c8e3,
    darker: #072f85,
    200: #0d47a1,
    // For slide toggle,
    contrast: (
            main: white,
            lighter: rgba(black, 0.87),
            darker: white,
        ),
);
$theme-accent: mat.m2-define-palette($mat-accent, main, lighter, darker);

$mat-warn: (
    main: #ff0000,
    lighter: #ffb3b3,
    darker: #ff0000,
    200: #ff0000,
    // For slide toggle,
    contrast: (
            main: white,
            lighter: rgba(black, 0.87),
            darker: white,
        ),
);
$theme-warn: mat.m2-define-palette($mat-warn, main, lighter, darker);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $theme-primary,
            accent: $theme-accent,
            warn: $theme-warn,
        ),
        typography: mat.m2-define-typography-config(),
    )
);

@include mat.elevation-classes();
@include mat.app-background();
@include mat.autocomplete-theme($theme);
@include mat.button-theme($theme);
@include mat.fab-theme($theme);
@include mat.icon-button-theme($theme);
@include mat.checkbox-theme($theme);
@include mat.datepicker-theme($theme);
@include mat.dialog-theme($theme);
@include mat.form-field-theme($theme);
@include mat.input-theme($theme);
@include mat.list-theme($theme);
@include mat.menu-theme($theme);
@include mat.progress-spinner-theme($theme);
@include mat.select-theme($theme);
@include mat.slide-toggle-theme($theme);
@include mat.slider-theme($theme);
@include mat.snack-bar-theme($theme);
@include mat.stepper-theme($theme);
@include mat.table-theme($theme);
@include mat.tooltip-theme($theme);
@include mat.option-theme($theme);

// DateRangePicker theme
@include carousel.date-range-picker-theme($theme);

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
    padding: 0 1.15em;
    margin: 0 0.65em;
    min-width: 3em;
    line-height: 36.4px;
}

.mat-standard-chip {
    padding: 0.5em 0.85em;
    min-height: 2.5em;
}
