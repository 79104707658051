/* You can add global styles to this file, and also import other style files */
.cdk-global-overlay-wrapper {
    z-index: 1040;
}

.medium-zoom-overlay {
    z-index: 100;
}

.medium-zoom-image {
    z-index: 101;
}

body {
    width: 100vw;
    height: 100vh;
}
