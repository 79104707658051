@use 'mixins/utilities';
@use 'variables/bootstrap-material-design';

.badge {
    border-radius: bootstrap-material-design.$border-radius-label;
    padding: 5px 12px;
    text-transform: uppercase;
    font-size: 10px;
    color: bootstrap-material-design.$white-color;

    @include utilities.badge-color();
}
