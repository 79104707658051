@use 'sass:string';
@use 'mixins/utilities';
@use 'variables/bootstrap-material-design';
@use 'variables/type';
@use 'bootstrap/scss/bootstrap';

// http://codepen.io/rosskevin/pen/EPPwRR?editors=110
.switch {
    label {
        position: relative;
        padding-left: bootstrap-material-design.$bmd-switch-width + bootstrap-material-design.$bmd-switch-label-padding; // absolutely positioned so add the radio size

        .bmd-switch-track {
            position: absolute;
            top: utilities.calc-top(bootstrap.$line-height-base, type.$font-size-base, bootstrap-material-design.$bmd-switch-height);
            left: 0;
            display: inline-block;
            width: bootstrap-material-design.$bmd-switch-width;
            height: bootstrap-material-design.$bmd-switch-height;
            cursor: pointer;
            background-image: linear-gradient(
                to right,
                bootstrap-material-design.$bmd-switch-unchecked-bg 0%,
                bootstrap-material-design.$bmd-switch-unchecked-bg 50%,
                bootstrap-material-design.$bmd-switch-checked-bg 50%,
                bootstrap-material-design.$bmd-switch-checked-bg 100%
            );
            background-position: 0%;
            background-size: bootstrap-material-design.$bmd-switch-width * 2 bootstrap-material-design.$bmd-switch-height;
            border-radius: bootstrap-material-design.$bmd-switch-width;
            transition: background-position 0.2s ease-in;

            &::after {
                position: absolute;
                top: 50%;
                left: 0;
                display: block;
                align-self: center;
                width: bootstrap-material-design.$bmd-switch-handle-size;
                height: bootstrap-material-design.$bmd-switch-handle-size;
                content: '';
                background: bootstrap-material-design.$bmd-switch-handle-unchecked-bg;
                border-radius: 100%;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
                transition:
                    left 0.2s ease-in,
                    background-color 0.2s ease-in,
                    transform 0.3s ease;
                transform: translateY(-50%);
            }

            // Jelly effect on click
            &:active::after {
                transform: translateY(-50%) scale3d(1.15, 0.85, 1);
            }
        }

        input {
            // Hide original checkbox, but don't use `display: none` to allow focus on it using keyboard
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            opacity: 0;

            &:checked {
                + .bmd-switch-track {
                    background-position: -100%;
                    &::after {
                        left: string.unquote(
                            'calc(100% - #{bootstrap-material-design.$bmd-switch-handle-size})'
                        ); //calc(100% - $bmd-switch-handle-size);
                        background-color: bootstrap-material-design.$bmd-switch-handle-checked-bg;
                    }
                }
            }

            &:disabled {
                // No jelly effect on disabled switchs
                + .bmd-switch-track:active::after {
                    transform: translateY(-50%);
                }

                + .bmd-switch-track {
                    cursor: default;
                    background: bootstrap-material-design.$bmd-switch-disabled-bg;
                    &::after {
                        background: bootstrap-material-design.$bmd-switch-handle-disabled-bg;
                    }
                }
            }
        }
    }
}
