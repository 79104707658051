@use 'sass:color';
@use '../core/misc';
@use '../core/mixins/vendor-prefixes';
@use '../core/variables/bootstrap-material-design';
@use '../core/variables/brand';
@use '../core/variables/modals';

a {
    color: bootstrap-material-design.$link-color;
    &:hover,
    &:focus {
        color: color.adjust(bootstrap-material-design.$link-color, $lightness: -5%);
        text-decoration: none;
    }

    &.text-info {
        &:hover,
        &:focus {
            color: color.adjust(brand.$brand-info, $lightness: -5%);
        }
    }

    & .material-icons {
        vertical-align: middle;
    }
}

a[data-toggle='collapse'][aria-expanded='true'] .caret,
.dropdown.open .caret,
.dropup.open .caret {
    @include vendor-prefixes.rotate-180();
}

.caret {
    @include vendor-prefixes.transition-all(bootstrap-material-design.$fast-transition-time, modals.$transition-ease-in);
}
